:root {
  /** colors **/
  --black: #221e22;
  --cream: #d0bca9;
  --cream-mid: #efe2d7;
  --dark-green: #225056;
  --green: #27ae60;
  --light-green: #2b646b;
  --light-gray: #878383;
  --gray: #f4f4f9;
  --off-white: #f5f1ed;
  --white: #ffffff;
  --error: #f00;
  /** spacing **/
  --unit: 5px;
  --sm-padding-horiz: 15px;
  --reading-line-height: 25px;
}

@font-face {
  font-family: "DM Sans";
  src:
    local("DM Sans"),
    url(./fonts/dm-sans.ttf) format("truetype");
}
@font-face {
  font-family: "DM Sans";
  src:
    local("DM Sans"),
    url(./fonts/dm-sans-bold.ttf) format("truetype");
  font-weight: bold;
}

@font-face {
  /** TODO: not necessarily "Light", this should be refactored to exclude "Light" from the font-family name... **/
  font-family: "FreightDisp Pro Light";
  src:
    local("FreightDisp Pro Light"),
    url(./fonts/freight-disp-pro-light-regular.ttf) format("truetype");
}

@font-face {
  font-family: "FreightDisp Pro Light";
  font-style: italic;
  src:
    local("FreightDisp Pro Light"),
    url(./fonts/freight-disp-pro-light-italic.ttf) format("truetype");
}

@font-face {
  font-family: "DM Mono";
  src:
    local("DM Mono"),
    url(./fonts/dm-mono.ttf) format("truetype");
}

body {
  margin: 0;
  font-family:
    DM Sans,
    Arial;
}

h1 {
  color: var(--black);
}

a,
a:visited,
a:hover,
a:active {
  color: inherit !important;
}

.u-textBold {
  font-weight: bold;
}

a.u-text-offWhite,
.u-text-offWhite {
  color: var(--off-white) !important;
}

.u-text-black {
  color: var(--black) !important;
}

.u-text-lightGray {
  color: var(--light-gray);
}

.u-text-white {
  color: var(--white);
}

.u-text-error {
  color: var(--error);
}

.u-text-darkGreen {
  color: var(--dark-green);
}

.u-bg-white {
  background-color: var(--white);
}

.u-bg-black {
  background-color: var(--black);
}

.u-bg-transition {
  transition: 600ms;
}

.u-bg-creamMid {
  background-color: var(--cream-mid);
}

.u-bg-gray {
  background-color: var(--gray);
}

.u-bg-darkGreen {
  background-color: var(--dark-green);
}

.u-border-cream {
  border: 1px solid var(--cream);
}

.u-opacity2 {
  opacity: 0.2;
}

.u-opacity5 {
  opacity: 0.5;
}

.u-opacity8 {
  opacity: 0.8;
}

.u-text-small {
  font-size: 12px;
}

/** small view port, under 767px **/
@media screen and (max-width: 767px) {
  h1 {
    font-size: 36px;
    margin: 45px 30px 8px;
  }
  h2 {
    font-size: 36px;
  }
  h3 {
    margin: 0;
    font-size: 24px;
    font-weight: 700;
  }
  h4 {
    font-size: 18px;
  }
  button.button--sm-arrow-left {
    position: relative;
    padding: 13.5px 39px !important;
    content: " ";
  }
  button.button--sm-arrow-left::after {
    content: " ";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-image: url("./common/arrows/left.svg");
    background-position: center;
    background-repeat: no-repeat;
    filter: invert(100%);
  }
  .serif-heading {
    margin: 0 30px 40px;
    font-size: 24px;
    line-height: 30px;
  }
  .u-sm-hidden {
    display: none !important;
  }
  .u-sm-flexAlignItemsStart {
    align-items: start !important;
  }
  .u-sm-flexCol {
    flex-direction: column !important;
  }
  .u-sm-sizeFull {
    width: 100% !important;
  }
  .notification {
    min-width: 100% !important;
    border-radius: 0 !important;
  }
  nav {
    padding: 10px 30px 0 14px;
  }
  nav.prev-step-nav {
    padding: 3px 0 0 16px;
  }
  p.tract-title {
    font-size: 36px;
  }
  .step-header {
    margin: 65px 30px 0;
    max-width: 300px;
  }
  .u-sm-textLeft {
    text-align: left !important;
  }
  .u-sm-paddingLeft0 {
    padding-left: 0 !important;
  }
  .u-sm-paddingVert2gu {
    padding-top: calc(var(--unit) * 2) !important;
    padding-bottom: calc(var(--unit) * 2) !important;
  }
  .u-sm-marginHoriz0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .u-sm-marginHoriz3gu {
    margin-left: calc(var(--unit) * 3) !important;
    margin-right: calc(var(--unit) * 3) !important;
  }
  .u-sm-marginVert2gu {
    margin-top: calc(var(--unit) * 2) !important;
    margin-bottom: calc(var(--unit) * 2) !important;
  }
  .u-sm-marginLeft6gu {
    margin-left: calc(var(--unit) * 6) !important;
  }
  .u-sm-marginRight0 {
    margin-right: 0 !important;
  }
  .u-sm-square {
    border-radius: 0 !important;
  }
}

/** mediuem view port, 767px - 1080px **/
@media screen and (min-width: 767px) and (max-width: 1080px) {
  h1 {
    font-size: 36px;
    margin: 45px 30px 8px;
  }
  h2 {
    font-size: 36px;
  }
  h3 {
    margin: 0;
    font-size: 24px;
    font-weight: 700;
  }
  h4 {
    font-size: 18px;
  }
  .serif-heading {
    margin: 0 30px 40px;
    font-size: 24px;
    line-height: 30px;
  }
  .u-md-hidden {
    display: none !important;
  }
  nav {
    padding: 1em 4em 5px;
  }
  nav.prev-step-nav {
    padding: 3px 0 0 16px;
  }
  p.tract-title {
    font-size: 36px;
  }
  .step-header {
    margin-top: 65px;
    margin-bottom: 0px;
  }
  .u-md-marginLeft9gu {
    margin-left: calc(var(--unit) * 9) !important;
  }
}

/** large view port, over 1080px **/
@media screen and (min-width: 1080px) {
  h1 {
    font-size: 54px;
    margin: 50px 0 0;
  }
  h2 {
    font-size: 48px;
  }
  h3 {
    margin: 0;
    font-size: 36px;
    font-weight: 700;
  }
  h4 {
    font-size: 18px;
  }
  .serif-heading {
    font-size: 32px;
    /* font-weight: 400; */
    line-height: 41px;
  }
  .u-lg-hidden {
    display: none !important;
  }
  nav {
    padding: 1em 4em;
  }
  nav.prev-step-nav {
    padding-top: 35px;
    padding-left: 65px;
  }
  p.tract-title {
    font-size: 48px;
  }
  .step-header {
    margin-top: 35px;
    margin-bottom: 0px;
  }
  .u-lg-flexRow {
    flex-direction: row !important;
  }
  .u-lg-margin6gu {
    margin: calc(var(--unit) * 6) !important;
  }
  .u-lg-marginLeft12gu {
    margin-left: calc(var(--unit) * 12) !important;
  }
  .u-lg-size1of2 {
    flex-basis: auto !important;
    width: 50% !important;
  }
}

.serif-heading {
  font-family: FreightDisp Pro Light;
  font-weight: 400;
}

.button {
  font-family:
    DM Sans,
    Arial;
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  padding: 10px 30px 11px;
  border-radius: 39px;
  border-width: 0;
  cursor: pointer;
}

.button.button--alert {
  color: var(--white);
  background: red;
}

.button.button--primary {
  background-color: var(--black);
  color: var(--white) !important;
}
.button.button--primary[disabled] {
  color: var(--light-gray) !important;
  cursor: not-allowed;
}
.button.button--primary.button--selected {
  background-color: var(--green);
}
.button.button--secondary {
  background-color: var(--cream);
  color: var(--black);
}
.button.button--tertiary {
  color: var(--white);
  font-family:
    DM Sans,
    Arial;
  font-size: 16px;
  font-weight: 500;
  background: none;
  border: none;
}
.button.button--tertiary[disabled] {
  color: var(--light-gray);
  cursor: not-allowed;
}
.button.button--large {
  font-size: 18px;
  font-weight: 700;
  line-height: 1;
  padding: 0.75em 1.5em;
  border-radius: 1.2em;
}
.button.button--small {
  padding: 6px 20px 7px;
}
.button.button--previous {
  position: relative;
}
.button.button--previous::before {
  background-image: url(./left-angle-bracket.svg);
  display: inline-flex;
  content: "";
  background-size: 15px 15px;
  height: 15px;
  width: 15px;
  position: absolute;
  top: 12px;
  left: 7px;
}

.button.button--left,
.button.button--right {
  background-image: url(./left-angle-bracket.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-color: transparent;
}
.button.button--right {
  transform: rotate(180deg);
}

nav a {
  text-decoration: none;
  padding: 1em;
}

nav.prev-step-nav {
  font-size: 14px;
  font-family:
    DM Sans,
    Arial;
}

.notification {
  font-size: 1em;
  background-color: var(--black);
  color: var(--off-white);
  box-sizing: border-box;
  width: calc(100% - 10em);
  border-radius: 0.5em;
  padding: 5px 20px 0;
}
.notification.notification--warning {
  background-color: red;
  border-radius: 0;
}
.notification.notification--success {
  background-color: green;
  color: white;
}

.u-invert {
  filter: invert();
}

button.button--close {
  border: 0;
  padding: 1.5em;
  background-color: transparent;
  color: var(--off-white);
  cursor: pointer;
  position: relative;
}
button.button--close::after {
  content: " ";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-image: url("./close.svg");
  background-position: center;
  background-repeat: no-repeat;
}
button.button--close.button--close-invert::after {
  filter: invert(100%);
}
button.button--close.button--close-large::after {
  height: 30px;
  width: 30px;
  top: 5px;
  background-size: cover;
}

/** HOME PAGE **/
.home__mailbox img {
  margin-top: 25px;
  margin-bottom: -42px;
}

p.tract-title {
  color: var(--white);
  font-weight: 700;
  font-family: FreightDisp Pro Light;
}

/** INPUTS **/

input {
  font-family:
    DM Sans,
    Arial;
  color: var(--white);
  padding: 0 5px 10px;
  font-size: 18px;
  background: none;
  border: none;
  border-radius: 0;
  border-bottom: 2px solid var(--white);
  outline: none !important;
  box-sizing: border-box;
}

input::placeholder,
textarea::placeholder {
  color: var(--off-white);
  opacity: 0.8;
  font-size: 18px;
}

.u-light input {
  color: var(--black);
  border-bottom-color: var(--dark-green);
}

.u-light select[disabled],
.u-light input[disabled],
.u-light .select-wrapper.select-wrapper--disabled::before {
  color: #a4abb5 !important;
  background-color: #e9ebee !important;
  border-color: #a4abb5 !important;
}

.u-light input::placeholder,
.u-light textarea::placeholder {
  color: var(--light-gray);
}

select {
  font-family:
    DM Sans,
    Arial;
  background: none;
  border: none;
  color: var(--white);
  padding: 0 5px 10px;
  border-bottom: 2px solid var(--white);
  font-size: 18px;
  box-sizing: border-box;
}

.u-light select {
  color: var(--black);
  border-bottom-color: var(--dark-green);
}

option {
  color: var(--black);
}

textarea {
  border: 0;
  font-size: 18px;
  background: transparent;
  font-family: DM Sans;
  color: var(--off-white);
  resize: none;
  outline: none !important;
}

.u-light textarea {
  color: var(--black);
}

.rounded {
  border-radius: 10px;
}

.rule {
  border-width: 1px;
  border-style: solid;
}

.rule.rule--transparent {
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.rule.rule--offWhite {
  border-color: var(--off-white);
}

.rule.rule--cream {
  border-color: var(--cream);
}

.rule.rule--gray {
  border-color: #e0e0e0;
}

.rule.rule--black {
  border-color: var(--black);
}

.rule.rule--thick {
  border-width: 2px;
}

.rule.rule--extra-thick {
  border-width: 3px;
}

.u-price {
  white-space: nowrap;
  font-family: DM Mono;
}

.donate-amount {
  border-radius: 5px !important;
  border: 2px solid var(--black);
  background-color: transparent;
  font-size: 18px;
  padding: 6px 0;
  cursor: pointer;
  color: var(--black);
}

.donate-amount.donate-amount--selected,
.donate-amount .donate-amount__other.donate-amount__other--selected {
  background-color: var(--black);
  color: var(--white);
}

.donate-amount.donate-amount--dark-bg {
  color: var(--white);
  border-color: rgba(255, 255, 255, 0.2);
}

.donate-amount.donate-amount--dark-bg.donate-amount.donate-amount--selected {
  border-color: var(--black);
}

.donate-amount .donate-amount__other {
  color: var(--black);
  min-width: 6ch !important;
  padding: 0 0 0 5px !important;
  border-bottom: none !important;
}

.donate-amount .donate-amount__other::placeholder {
  color: var(--black);
  opacity: 0.4;
}

.donate-amount .donate-amount__other:focus::placeholder,
.donate-amount
  .donate-amount__other.donate-amount__other--dark-bg::placeholder {
  color: var(--white);
}

.custom-select {
  position: relative;
}
.custom-select select {
  padding: 0 12px 5px 12px;
  /** remove default select arrow **/
  appearance: none;
}

/** custom select arrow **/
.custom-select::after {
  content: " ";
  position: absolute;
  top: 5px;
  right: 2px;
  height: 8px;
  width: 8px;
  border-bottom: 2px solid var(--white);
  border-right: 2px solid var(--white);
  background-color: transparent;
  pointer-events: none;
  transform: rotate(45deg);
}

.u-light .select-wrapper {
  position: relative;
}
.u-light .select-wrapper select {
  appearance: none;
  border-bottom: 2px solid var(--black);
  background-color: transparent;
  padding: 0 35px 10px 5px;
  cursor: pointer;
}
.u-light .select-wrapper::before {
  content: " ";
  position: absolute;
  top: 3px;
  right: 15px;
  height: 18px;
  width: 18px;
  border-radius: 50%;
  background-color: var(--black);
  pointer-events: none;
}
.u-light .select-wrapper::after {
  content: " ";
  position: absolute;
  top: 10px;
  right: 19px;
  width: 0;
  height: 0;
  border-width: 5px !important;
  border-top: solid var(--off-white);
  border-right: solid transparent;
  border-left: solid transparent;
  pointer-events: none;
}
.u-light .select-wrapper.select-wrapper--dark-green select {
  padding: 12px 7px 18px 15px;
  border-bottom: 2px solid var(--dark-green);
  background-color: var(--gray);
}
.u-light .select-wrapper.select-wrapper--dark-green::before {
  top: 15px;
  background-color: var(--light-green);
}
.u-light .select-wrapper.select-wrapper--dark-green::after {
  top: 22px;
}

.removable {
  position: relative;
}

/** X in button **/
.removable::before,
.removable::after {
  content: "";
  position: absolute;
  background: var(--black);
  height: 1px;
  right: -6px;
  top: 1px;
  width: 10px;
  pointer-events: none;
}
.removable::after {
  transform: rotate(45deg);
}
.removable::before {
  transform: rotate(-45deg);
  z-index: 1;
}

.removable .removable__button {
  position: absolute;
  right: -9px;
  top: -7px;
  border-radius: 50%;
  height: 16px;
  width: 16px;
  border: 0;
  cursor: pointer;
}

/** MARGIN **/
.u-margin3gu {
  margin: calc(var(--unit) * 3);
}

.u-marginTop1gu {
  margin-top: calc(var(--unit) * 1);
}

.u-marginTop2gu {
  margin-top: calc(var(--unit) * 2);
}

.u-marginTop3gu {
  margin-top: calc(var(--unit) * 3);
}

.u-marginTop4gu {
  margin-top: calc(var(--unit) * 4) !important;
}

.u-marginTop5gu {
  margin-top: calc(var(--unit) * 5);
}

.u-marginTop6gu {
  margin-top: calc(var(--unit) * 6) !important;
}

.u-marginRight1gu {
  margin-right: calc(var(--unit) * 1);
}

.u-marginRight2gu {
  margin-right: calc(var(--unit) * 2);
}

.u-marginRight3gu {
  margin-right: calc(var(--unit) * 3);
}

.u-marginRight5gu {
  margin-right: calc(var(--unit) * 5);
}

.u-marginBottom3gu {
  margin-bottom: calc(var(--unit) * 3);
}

.u-marginBottom4gu {
  margin-bottom: calc(var(--unit) * 4);
}

.u-marginBottom5gu {
  margin-bottom: calc(var(--unit) * 5);
}

.u-marginBottom6gu {
  margin-bottom: calc(var(--unit) * 6);
}

.u-marginLeft2gu {
  margin-left: calc(var(--unit) * 2);
}

.u-marginVert2gu {
  margin-top: calc(var(--unit) * 2);
  margin-bottom: calc(var(--unit) * 2);
}

.u-marginVert3gu {
  margin-top: calc(var(--unit) * 3);
  margin-bottom: calc(var(--unit) * 3);
}

.u-marginVert4gu {
  margin-top: calc(var(--unit) * 4);
  margin-bottom: calc(var(--unit) * 4);
}

.u-marginVert6gu {
  margin-top: calc(var(--unit) * 6);
  margin-bottom: calc(var(--unit) * 6);
}

.u-marginHoriz0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

/** PADDING **/
.u-paddingLeft2gu {
  padding-left: calc(var(--unit) * 2);
}

.u-paddingHoriz2gu {
  padding-left: calc(var(--unit) * 2);
  padding-right: calc(var(--unit) * 2);
}

.u-padding3gu {
  padding: calc(var(--unit) * 3);
}

.u-padding4gu {
  padding: calc(var(--unit) * 4);
}

.u-padding6gu {
  padding: calc(var(--unit) * 6);
}

/** normalize **/
*,
*:before,
*:after {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -ms-box-sizing: border-box;
}
