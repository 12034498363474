.recipient-form {
  border: 1px solid rgba(208, 188, 169, 0.5);
  padding: 30px;
  margin: 30px 0;
  position: relative;
}
@media screen and (max-width: 767px) {
  .recipient-form {
    margin-top: 20px;
  }
}

.recipient-form button.button--close {
  position: absolute;
  top: 10px;
  right: 10px;
}

.recipient-form input[type="text"],
.recipient-form select {
  margin-bottom: 15px;
}

.recipient-form .recipient-form__spacer {
  width: 15px;
}

.recipient-form .rule--marginTop {
  margin-top: 8px;
}

.recipient-form .rule--marginBottom {
  margin-bottom: 22px;
}
