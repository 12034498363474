.about-page.about-page--light {
  overflow: hidden;
  padding-top: 1px;
  padding-bottom: 1px;
  background-color: var(--off-white);
}
.about-page .about-page__section {
  border-radius: 10px;
  border: 1px solid rgba(245, 241, 237, 0.2);
}

.about-page.about-page--light .about-page__section {
  border-color: var(--cream);
  padding-left: 20px;
  padding-right: 20px;
}

.about-page.about-page--light h2 {
  color: var(--black);
  margin-bottom: 8px;
}

.about-page .about-page__mission {
  margin: 8px 30px;
  color: var(--cream);
  font-size: 18px;
}

.about-page .about-page__section--lg-half p {
  line-height: var(--reading-line-height);
}

.about-page .about-page__section--no-border {
  border-width: 0;
}

/** Statement of Faith **/
.about-page .about-page__sof-details {
  max-width: 640px;
}

.about-page .about-page__sof-cards {
  gap: 15px;
}

.about-page .about-page__sof-card {
  border-radius: 10px;
  background-color: var(--off-white);
  flex-basis: 33%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: left;
  padding: 30px;
}

.about-page .about-page__sof-card h4 {
  position: relative;
  margin-top: 0;
  margin-bottom: 34px;
  color: var(--black);
}
.about-page .about-page__sof-card h4::after {
  position: absolute;
  content: " ";
  width: 22px;
  height: 3px;
  top: 44px;
  left: 0;
  background-color: var(--cream);
}

.about-page .about-page__sof-card p {
  line-height: var(--reading-line-height);
}

.about-page .about-page__sof-card button {
  margin-top: auto;
  border: 0;
  background: transparent;
  position: relative;
  font-size: 16px;
  font-weight: 500;
  text-align: left;
  color: var(--black);
}

.about-page .about-page__sof-card button::after {
  content: "";
  position: absolute;
  top: -5px;
  left: 0;
  width: 100%;
  height: 1px;
  background: #e0e0e0;
}
/** LARGE **/
@media screen and (min-width: 1080px) {
  .about-page {
    padding: 0 40px;
  }
  .about-page .about-page__section {
    margin: 15px 40px;
  }
  .about-page .about-page__section.about-page__section--mission {
    padding: 80px 160px;
  }
  .about-page .about-page__mission-graphic {
    margin-top: 48px;
  }
  /** Our Tracts & One Message Many Ways **/
  .about-page .about-page__section--lg-half {
    padding: 5px 70px 50px;
  }
  .about-page .about-page__section--lg-half:first-child {
    margin-right: 20px;
  }
  .about-page .about-page__section--lg-half:last-child {
    margin-left: 20px;
  }

  .about-page .about-page__section--sof {
    padding: 32px 80px 98px;
  }
  .about-page .about-page__sof-cards {
    gap: 10px;
  }
  .about-page .about-page__ready {
    margin-top: 160px;
  }
}

/** MEDIUM **/
@media screen and (min-width: 767px) and (max-width: 1080px) {
  .about-page {
    padding: 0 15px;
  }
  .about-page .about-page__section {
    margin: 15px 15px;
  }
  .about-page .about-page__section.about-page__section--mission {
    padding: 40px 40px;
  }
  .about-page .about-page__mission-message {
    margin-top: 28px;
  }
  .about-page .about-page__mission-graphic {
    margin-top: -17px;
  }
  .about-page .about-page__section--lg-half {
    padding: 0 30px 15px;
  }
  .about-page .about-page__section--lg-half h2 {
    margin-bottom: 15px;
  }
  .about-page .about-page__section--sof {
    padding: 32px 30px 98px;
  }
  .about-page .about-page__ready {
    margin-top: 76px;
  }
}

/** SMALL **/
@media screen and (max-width: 767px) {
  .about-page {
    padding: 0 8px;
  }
  .about-page .about-page__section {
    margin: 15px 7px;
  }
  .about-page .about-page__section.about-page__section--mission {
    margin-top: 25px;
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .about-page .about-page__mission {
    margin: 0px 30px;
    line-height: 1rem;
  }
  .about-page .about-page__mission-message {
    margin-top: 18px;
  }
  .about-page .about-page__mission-graphic {
    margin-top: -20px;
    margin-left: 30px;
  }
  .about-page .about-page__serif-heading {
    margin-bottom: 30px;
  }
  .about-page .about-page__serif-heading.about-page__serif-heading--small {
    max-width: 238px;
  }
  .about-page .about-page__section--lg-half {
    padding: 0 30px 15px;
  }
  .about-page .about-page__section--lg-half h2 {
    margin-bottom: 15px;
  }
  .about-page .about-page__section--sof {
    padding: 32px 15px 98px;
  }
  .about-page .about-page__ready {
    margin-left: -35px;
    margin-right: -35px;
  }
}

/** PROFITS **/
.about-page .about-page__profits {
  max-width: 664px;
}
