.text-input {
  border: 0;
  padding: 0;

  font-size: 16px;
  color: var(--black);
}

.text-input legend {
  position: absolute;
  transition: 250ms;
  pointer-events: none;
}

.text-input.text-input--active legend {
  font-size: 8px;
  opacity: 0.6;
  transform: translateY(-16px);
  font-weight: bold;
}

.text-input.text-input--empty legend {
  opacity: 0.4;
}
