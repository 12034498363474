.admin-nav {
  display: flex;
  flex-direction: column;
  min-width: 220px;
  width: 220px;
  padding: 10px 0;
  border-right: 2px solid black;

  h4 {
    padding: 15px 10px;
    margin: 0;
  }

  a.active {
    font-weight: bold;
    background-color: black;
    color: white !important;
  }

  a.inactive:hover {
    background-color: gray;
    color: white !important;
  }

  button {
    margin: auto 10px 15px;
  }
}
