.give {
  max-width: 1100px;
  margin-left: auto;
  margin-right: auto;
}

.give .give__list-item {
  display: flex;
  margin-bottom: 20px;
}

.give .give__list-number {
  padding: 24px;
  background-color: var(--black);
  border-radius: 50%;
  max-width: 50px;
  height: 50px;
  color: var(--white);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
}

.give .give__ways {
  border-radius: 10px;
  background-color: var(--white);
  padding: 40px;
  line-height: 35px;
}

.give .give__amount {
  display: flex;
  flex-direction: column;
  background-color: var(--dark-green);
  border-radius: 10px 10px 0 0;
  margin-bottom: 15px;
  color: var(--white);
}

.give .give__column-two {
  border-radius: 0 0 10px 10px;
  margin-bottom: 60px;
}

@media screen and (min-width: 1080px) {
  .give {
    padding: 0 80px;
  }
  .give .give__amount {
    padding: 30px 40px;
  }
  .give .give__column-two {
    margin-left: 28px;
  }
}
@media screen and (min-width: 767px) and (max-width: 1080px) {
  .give {
    padding: 0 30px;
  }
  .give .give__amount {
    padding: 30px 40px;
  }
  .give .give__column-two {
    margin-left: 28px;
  }
}
@media screen and (max-width: 767px) {
  .give .give__list {
    padding: 15px;
  }
  .give .give__amount {
    padding: 20px;
  }
}
