.footer-nav {
  border-top: 1px solid rgba(255, 255, 255, 0.4);
  padding-top: 34px;
  padding-bottom: 34px;
  box-sizing: border-box;
}

@media screen and (min-width: 767px) {
  .footer-nav {
    padding-right: 144px;
  }
}

@media screen and (max-width: 767px) {
  .footer-nav {
    padding-left: 30px;
    padding-right: 30px;
  }
}
