#modal-root {
  position: absolute;
  top: 68px;
  left: 0;
}

#modal-root .overlay {
  position: absolute;
  background: rgba(34, 30, 34, 0.4);
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
}

#modal-root .modal-menu {
  box-shadow: -16px 55px 54px rgba(0, 0, 0, 0.28);
  border-radius: 10px;
  margin: 10px 14px;
  background: var(--white);
}

#modal-root .modal-menu a {
  font-size: 26px;
  color: var(--black);
  padding: 20px;
  text-decoration: none;
}

#modal-root .modal-menu .line {
  height: 1px;
  background: var(--gray);
  width: calc(100% - 60px);
  margin: 5px 30px;
}
