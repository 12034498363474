.full-message__title {
  border-top: 2px solid var(--light-green);
  border-bottom: 2px solid var(--light-green);
  font-size: 20px;
  box-sizing: border-box;
}
@media screen and (min-width: 767px) {
  .full-message__title {
    padding: 30px 81px;
  }
}

@media screen and (max-width: 767px) {
  .full-message__title {
    padding: 15px 30px;
  }
}
@media screen and (max-width: 631px) {
  .full-message__title {
    margin-top: 10px;
  }
}

/** same as .serif-heading **/
.full-message section h4 {
  font-family: FreightDisp Pro Light;
  font-size: 32px;
  font-weight: 400;
  line-height: 41px;
}

.full-message section p {
  font-size: 18px;
  line-height: 30px;
  margin-top: 30px;
  letter-spacing: -0.02em;
}

.full-message section blockquote {
  font-size: 18px;
  margin-left: 20px;
  border-left: 2px solid var(--white);
  padding-left: 15px;
}

.full-message section ol li {
  margin-bottom: 25px;
}

.full-message {
  margin: 0 auto;
}

/** medium/large viewport **/
@media screen and (min-width: 767px) {
  .full-message {
    max-width: 490px;
  }
}

/** small viewport **/
@media screen and (max-width: 767px) {
  .full-message {
    margin: 30px;
  }
  .full-message section h2 {
    margin-bottom: 15px;
  }
  .full-message section h4 {
    margin: 0;
  }
}
