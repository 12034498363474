.rounded-section {
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  background-color: var(--white);
  padding: 30px;
  margin: 5px 15px;
}

.rounded-section.rounded-section--row {
  flex-direction: row !important;
}

@media screen and (max-width: 767px) {
  .rounded-section.rounded-section--sm-square {
    border-radius: 0;
    margin-left: 0;
    margin-right: 0;
  }
}

@media screen and (max-width: 1080px) {
  .rounded-section {
    padding: 20px;
  }
}
