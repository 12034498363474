.add-on {
  background: var(--white);
  /* padding: 30px; */
  margin-bottom: 20px;
}

.add-on .slick-arrow::before {
  content: none;
}

.add-on .slick-arrow {
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(../../../left-angle-bracket.svg);
  background-color: transparent;
  border-radius: 50%;
  padding: 20px;
  cursor: pointer;
}

.add-on .slick-arrow.slick-next {
  top: calc(50% - 20px);
  transform: rotate(180deg);
}

.add-on__header {
  margin: 45px 30px;
  font-size: 24px;
}

.add-on .add-on__title {
  font-size: 22px;
  font-weight: bold;
}

.add-on .add-on__slider-container {
  margin: 0 30px;
}

.add-on .add-on__description {
  font-size: 18px;
  margin: 0 auto;
  padding-bottom: 15px;
}

.add-on .add-on__divider {
  margin: 35px 30px;
}

.add-on .add-on__price {
  padding: 17px 20px 18px;
  margin-right: 10px;
  font-size: 14px;
  color: #976b42;
}

.add-on .add-on__price .add-on__price-each {
  color: #c2a78c;
}

.add-on__footer {
  margin: 0 30px 30px;
}

@media screen and (min-width: 625px) and (max-width: 767px) {
  .add-on__tract-details {
    flex-direction: row !important;
    justify-content: space-evenly;
    margin-left: 30px;
    margin-right: 30px;
  }
  .add-on__tract-details > div:first-child {
    margin-right: 30px;
  }
}

/** hack with max-width to make the <Slider /> play nice... **/
/** small view port, under 767px **/
@media screen and (max-width: 768px) {
  .add-on__slider-container {
    max-width: calc(100vw - 90px);
  }
}

/** mediuem view port, 767px - 1080px **/
@media screen and (min-width: 767px) {
  .add-on__slider-container {
    max-width: calc(100vw * 5 / 12 - 120px);
  }
}
