.endorsements {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
}

.endorsements .endorsements__container {
  display: flex;
  flex-direction: column;
  border: 1px solid var(--cream);
  border-radius: 10px;
  padding: 30px;
  max-width: 360px;
  margin-left: 16px;
  margin-top: 16px;
}

.endorsements .endorsements__image-container {
  max-width: 88px;
}

.endorsements .endorsements__image {
  max-height: 100%;
  max-width: 100%;
}

.endorsements .endorsements__name {
  margin-bottom: 0;
}

.endorsements .endorsements__role {
  margin-top: 4px;
  margin-bottom: 10px;
}

.endorsements .endorsements__about {
  margin-bottom: 30;
}
