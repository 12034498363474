.shipping .shipping__option:not(:last-child) {
  border-bottom: 1px solid var(--cream);
}
.shipping .shipping__option .checkmark {
  top: 20px;
  left: 15px;
}

.shipping .shipping__option.radio {
  margin-bottom: 0;
}
