input[type="radio"] + span {
  font-family: DM Sans, Arial;
  font-size: 16px;
  font-weight: 500;
  color: var(--white);
  padding-left: 11px;
  margin-left: 24px;
}

.radio {
  position: relative;
  margin-bottom: 8px;
  cursor: pointer;
}

/* Hide the browser's default radio button */
.radio input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 3px;
  left: 0;
  height: 16px;
  width: 16px;
  background-color: #eee;
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.radio:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the radio button is checked */
.radio input:checked ~ .checkmark {
  background-color: var(--white);
}
.u-light .radio input:checked ~ .checkmark {
  background-color: #ccc;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.radio.radio--light-green .checkmark:after {
  background: var(--light-green);
}

/* Show the indicator (dot/circle) when checked */
.radio input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.radio .checkmark:after {
  top: 4px;
  left: 4px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: var(--black);
}
