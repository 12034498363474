@media screen and (min-width: 768px) {
  button.add-recipient {
    margin-bottom: 40px;
  }
  .forms-container {
    margin-right: 4em;
    min-height: 800;
  }
  .tract-divider {
    margin: 38px 126px 30px;
  }
}

@media screen and (max-width: 767px) {
  button.add-recipient {
    margin-bottom: 15px;
  }
  .forms-container {
    box-sizing: border-box;
    width: 100vw !important;
    padding: 0 var(--sm-padding-horiz);
  }
  .forms-container .forms-container__divider {
    margin-left: calc(-1 * var(--sm-padding-horiz));
    margin-right: calc(-1 * var(--sm-padding-horiz));
  }
  .tract-divider {
    margin: 49px 15px 0;
  }
}
