nav.mtg-header {
  transition: 0.5s;
}
nav.mtg-header.mtg-header--open {
  background: var(--light-green);
}
nav.mtg-header.mtg-header--open img,
nav.mtg-header.mtg-header--light img {
  filter: invert(100%);
}

nav.mtg-header button.hamburger {
  width: 23px;
  height: 16px;
  position: relative;
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}
nav.mtg-header button.hamburger:before,
nav.mtg-header button.hamburger:after {
  content: "";
  position: absolute;
  background: var(--black);
  height: 3px;
  left: 0;
  width: 100%;
  border-radius: 3px;
  transition: 0.5s;
}
nav.mtg-header.mtg-header--light button.hamburger:before,
nav.mtg-header.mtg-header--light button.hamburger:after {
  background: var(--off-white);
}
nav.mtg-header.mtg-header--open button.hamburger:before,
nav.mtg-header.mtg-header--open button.hamburger:after {
  background: var(--white);
}
nav.mtg-header button.hamburger:before {
  top: 0;
}
nav.mtg-header button.hamburger:after {
  top: 10px;
}
nav.mtg-header.mtg-header--open button.hamburger:before {
  transform: rotate(45deg);
  top: 6px;
}
nav.mtg-header.mtg-header--open button.hamburger:after {
  transform: rotate(-45deg);
  top: 6px;
}
