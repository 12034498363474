.preview-bulk-tract-order {
  display: flex;
  width: 100%;
}

@media screen and (min-width: 550px) {
  .preview-bulk-tract-order .preview-bulk-tract-order__price {
    margin-left: auto;
    padding-left: 10px;
  }
}

@media screen and (max-width: 550px) {
  .preview-bulk-tract-order {
    flex-direction: column;
    align-items: flex-end;
  }

  .preview-bulk-tract-order p {
    margin: 5px;
  }
}
