.ribbon {
  margin-top: 35px;
}
.ribbon .ribbon__chevron {
  background-image: url("./chevron-first.svg");
  background-position: center;
  background-repeat: no-repeat;
  height: 12px;
  width: 12px;
  position: relative;
  padding-right: 10px;
  margin-left: 5px;
  margin-right: 5px;
}

.ribbon .ribbon__chevron::after {
  content: " ";
  background-image: url("./chevron-second.svg");
  background-position: center;
  background-repeat: no-repeat;
  height: 12px;
  width: 12px;
  position: absolute;
  top: 0;
  right: 2px;
}

@media screen and (min-width: 767px) {
  .ribbon {
    font-size: 18px;
  }
}

@media screen and (max-width: 766px) {
  .ribbon {
    font-size: 14px;
  }
}
