.tract-preview {
  margin-top: 60px;
  margin-bottom: 60px;
  width: 200px;
  height: 290px;
  position: relative;
}

.tract-preview--expand-margin {
  margin-left: auto;
  margin-right: auto;
}

.tract-preview__background {
  position: absolute;
  height: 290px;
  width: 320px !important;
  left: -71px;
  z-index: 0;
}

.tract-preview__cover {
  z-index: 1;
  transform: translateY(40px) scale(1.3);
  max-height: 100%;
}

@media screen and (max-width: 767px) {
  .tract-preview--prev {
    transform: translateX(45vw);
    scale: 0.65;
  }

  .tract-preview--next {
    transform: translateX(-45vw);
    scale: 0.65;
  }
}

.tract-preview__gallery {
  position: absolute;
  z-index: 2;
}

@media screen and (max-width: 900px) {
  .tract-preview.tract-preview--with-gallery {
    margin-bottom: 80px !important;
  }
  .tract-preview__gallery {
    flex-direction: row;
    bottom: -80px;
    width: 285px;
  }
}
@media screen and (min-width: 901px) {
  .tract-preview__gallery {
    flex-direction: column;
    transform: translateX(-230px);
    height: 100%;
    padding: 15px 0 6px 0;
  }
}
