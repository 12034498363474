.bulk-gospel-tract {
  padding: 10px 20px 10px 25px;
}

.bulk-gospel-tract.bulk-gospel-tract--selected {
  border: 2px solid black;
}

.bulk-gospel-tract .checkmark {
  top: calc(50% - 8px);
  left: 15px;
}

.bulk-gospel-tract > div {
  padding-left: 25px;
}
