.team-member {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
}

.team-member .team-member__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 333px;
  margin-left: 16px;
  margin-top: 16px;
}

.team-member .team-member__image-container {
  border-radius: 10px;
}

.team-member .team-member__image {
  max-height: 100%;
  max-width: 100%;
}

.team-member p,
.team-member h4 {
  align-self: flex-start;
}

.team-member .team-member__name {
  margin-bottom: 0;
}

.team-member .team-member__role {
  margin-top: 4px;
  margin-bottom: 10px;
}

.team-member .team-member__about {
  margin-bottom: 30;
}
