.tabs {
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.tabs .tabs__tab {
  font-size: 16px;
  padding: 5px 10px;
  border-radius: 0;
  border: 0;
}
.tabs .tabs__tab--active {
  border-bottom: 2px solid black;
}
