.mtg-footer {
  display: flex;
  align-items: center;
}

nav.mtg-footer.mtg-footer--light a.social-link {
  filter: invert(100%);
}

@media screen and (max-width: 903px) {
  .mtg-footer {
    font-size: 14px;
    flex-direction: column;
  }
  .mtg-footer .mtg-footer__legal {
    padding: 10px 0 24px;
  }
  .mtg-footer > * {
    order: 1;
  }
  .mtg-footer > .mtg-footer__social-links {
    margin: 20px 0;
    order: 0;
  }
  nav.mtg-footer a.social-link img {
    width: 27px;
    height: 27px;
  }
}
@media screen and (min-width: 904px) {
  .mtg-footer > .mtg-footer__social-links {
    margin-left: auto;
  }
}

@media screen and (min-width: 881px) {
  .mtg-footer {
    padding: "2em 3em";
  }
}
