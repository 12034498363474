p.tract-details__price {
  color: var(--white);
  font-size: 22px;
  font-weight: 500;
  font-family: DM Mono, Menlo;
}
p.tract-details__shipping {
  color: var(--white);
  font-size: 11px;
  font-weight: 500;
  font-family: DM Mono, Menlo;
  padding-left: 9px;
}

.tract-info {
  max-width: 960px;
  margin: 0 30px;
  color: var(--white);
}

.tract-info .tract-info__section {
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  padding-top: 10px;
  box-sizing: border-box;
  margin-top: 20px;
}

.tract-info .tract-info__section-title {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 10px;
  text-transform: uppercase;
}

.tract-info .tract-info__section-details--about {
  font-size: 16px;
  line-height: 25px;
  font-weight: 400;
}

.tract-info .tract-info__section-details--product-details {
  font-size: 14px;
  line-height: 25px;
  font-weight: 400;
  margin-bottom: 20px;
}

.tract-info .tract-info__read-tract {
  margin: 20px 0;
}

@media screen and (min-width: 767px) {
  .tract-info .tract-info__section--left,
  .tract-info .tract-info__section--right {
    flex-basis: calc(50% - 20px);
  }
  .tract-info .tract-info__section--left {
    margin-right: 20px;
  }
  .tract-info .tract-info__section--right {
    margin-left: 20px;
  }
}
