:root {
  --thumbnail-size: 60px;
}
.image-gallery .image-gallery__thumbnail {
  width: var(--thumbnail-size);
  height: var(--thumbnail-size);
  opacity: 0.5;
  transition: 250ms;
}
.image-gallery .image-gallery__thumbnail:hover {
  opacity: 1;
}

.image-gallery .image-gallery__thumbnail-button {
  border-radius: 0;
  outline: none;
  border: 0;
  padding: 0;
  cursor: pointer;
}
