@media screen and (max-width: 767px) {
  .who-is-mailing {
    margin: 75px 0 100px;
    align-self: stretch;
  }
  .who-is-mailing label {
    margin: 30px 0 20px;
  }
  .who-is-mailing input {
    text-align: center;
    width: calc(100% - 120px);
    max-width: 430px;
    margin: 0 60px;
  }
  .who-is-mailing__button {
    margin: 23px 30px 23px auto;
  }
}

@media screen and (min-width: 767px) {
  .who-is-mailing {
    margin: 115px 0 150px;
  }
  .who-is-mailing input {
    margin: 0 15px;
  }
  .who-is-mailing__button {
    margin: 1.5em 8em 1.5em auto;
  }
}

.who-is-mailing {
  color: var(--white);
  font-size: 18px;
}
