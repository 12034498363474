.select-tract {
  z-index: 3;
}

@media screen and (min-width: 767px) {
  .select-tract {
    margin-left: 133px;
  }
  .cyt__slider-container .slick-arrow {
    position: absolute;
    top: calc(50% - 15px);
    background: none;
    border: 1px solid var(--cream);
    height: 100px;
    width: 100px;
    padding: 23px 0 0 26px;
    border-radius: 50%;
    cursor: pointer;
    z-index: 2;
    background-repeat: no-repeat;
    background-position: center;
  }
  .cyt__slider-container .slick-arrow.slick-prev {
    background-image: url("../../../common/arrows/left.svg");
  }
  .cyt__slider-container .slick-arrow.slick-next {
    background-image: url("../../../common/arrows/right.svg");
  }
}

@media screen and (max-width: 767px) {
  .select-tract {
    margin-bottom: 57px;
  }
}

.slick-arrow.slick-prev::before,
.slick-arrow.slick-next::before {
  content: " ";
}

/** slick slider stuff **/
.cyt__slider-container .slick-list {
  overflow: visible;
}
.cyt__slider-container .slick-slide,
.cyt__slider-container .slick-slide .tract-preview {
  margin: auto;
}

@media screen and (max-width: 576px) {
  .cyt__slider-container
    .slick-slide:not(.slick-active)
    .tract-preview__background {
    transform: scale(0.5);
    transition: 250ms;
  }
  .cyt__slider-container .slick-slide:not(.slick-active) .tract-preview__cover {
    transform: scale(0.8);
    transition: 250ms;
  }
}

.cyt__slider-container .slick-dots li.slick-active button::before,
.cyt__slider-container .slick-dots li button:hover::before {
  opacity: 0.75;
  color: white;
}
.cyt__slider-container .slick-arrow.slick-prev::before,
.cyt__slider-container .slick-arrow.slick-next::before {
  content: " ";
}

/** hack with max-width to make the <Slider /> play nice... */
.cyt__slider-container {
  max-width: 81vw;
}
