.light-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.light-header > * {
  margin-top: 0;
  margin-bottom: 0;
}

.light-header h2 {
  max-width: 960px;
  line-height: 1.1;
}

.light-header p {
  max-width: 530px;
}

.light-header.light-header--cta p {
  color: var(--light-gray);
  margin: 27px 0 0;
}

@media screen and (min-width: 1080px) {
  .light-header {
    margin-top: 48px;
    margin-bottom: 92px;
  }
  .light-header p {
    padding: 0 30px;
  }
  .light-header.light-header--cta {
    margin-bottom: 40px;
  }
  .light-header.light-header--cta h2 {
    font-size: 54px;
  }
}
@media screen and (min-width: 767px) and (max-width: 1080px) {
  .light-header {
    margin: 48px 30px 92px;
  }
  .light-header.light-header--cta {
    margin-bottom: 40px;
  }
}
@media screen and (max-width: 767px) {
  .light-header {
    margin: 33px 30px 77px;
  }
  .light-header.light-header--cta {
    margin-bottom: 40px;
  }
}
