.start-buttons {
  display: flex;
  min-width: 476px;
}

.start-buttons button {
  width: 50%;
}

.start-buttons .start-buttons__watch-the-film {
  background-color: var(--dark-green);
  color: var(--white);
}

@media screen and (min-width: 767px) {
  .start-buttons button:first-child {
    margin-right: 10px;
  }
}

@media screen and (max-width: 767px) {
  .start-buttons {
    padding: 0 51px;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  .start-buttons button:first-child {
    margin-bottom: 20px;
  }

  .start-buttons button {
    width: calc(100% - 60px);
  }
}
